import { stringify } from 'query-string';

import { QueryParams } from 'types';
import { apiFetch } from 'utils/http';

import { CreateEnterpriseSubscriptionRequestBody, EntSubUpdatePayload } from 'types/api';

export const EnterpriseSubscriptionService = {
  list: ({ entId }: { entId: number }) => apiFetch(`/admin/enterprise/${entId}/subscriptions`),

  create: ({
    enterpriseId,
    body,
  }: {
    enterpriseId: number;
    body: CreateEnterpriseSubscriptionRequestBody;
  }) =>
    apiFetch(`/admin/enterprise/${enterpriseId}/subscriptions`, {
      method: 'POST',
      body,
    }),

  details: ({ entId, subId }: { entId: number; subId: number }) =>
    apiFetch(`/admin/enterprise/${entId}/subscriptions/${subId}`),

  update: ({
    entId,
    subId,
    payload: body,
  }: {
    entId: number;
    subId: number;
    payload: EntSubUpdatePayload;
  }) =>
    apiFetch(`/admin/enterprise/${entId}/subscriptions/${subId}`, {
      method: 'PUT',
      body,
    }),

  cancel: ({ subscriptionIds }: { subscriptionIds: number[] }) =>
    apiFetch('/admin/enterprise/subscriptions/cancel', {
      method: 'POST',
      body: { subscriptionIds },
    }),

  listMembers: ({ entId, subId }: { entId: number; subId: number }) =>
    apiFetch(`/admin/enterprise/${entId}/subscriptions/${subId}/members`),

  addMember: ({
    entId,
    subId,
    ...userInfo
  }: {
    entId: number;
    subId: number;
    phone?: string;
    email?: string;
    vehiclePlate?: string;
  }) => {
    const endpoint = userInfo.vehiclePlate ? 'add-vehicle' : 'add-user';
    return apiFetch(
      `/admin/enterprise/${entId}/subscriptions/${subId}/${endpoint}?${stringify(userInfo as QueryParams)}`,
      { method: 'POST' },
    );
  },

  deleteMembers: ({ entId, subId, userIds }: { entId: number; subId: number; userIds: number[] }) =>
    apiFetch(`/admin/enterprise/${entId}/subscriptions/${subId}/members`, {
      method: 'DELETE',
      body: {
        userIds,
      },
    }),

  createRedemptionCode: ({ entId, subId }: { entId: number; subId: number }) =>
    apiFetch(`/admin/enterprise/${entId}/subscriptions/${subId}/redemption-codes`, {
      method: 'POST',
    }),

  getRedemptionCode: ({ entId, subId }: { entId: number; subId: number }) =>
    apiFetch(`/admin/enterprise/${entId}/subscriptions/${subId}/redemption-codes`),
};
