import { createEffect } from 'effector';

import { IamUserService } from 'apps/admin/services/iam/IamUserService';

import { showAlert } from 'state/alert';

export const getOperatorRoleDetails = createEffect({
  handler: async ({ roleId, roleType }: { roleId: string; roleType: string }) =>
    IamUserService.getRoleDetails({ roleId, roleType }).catch((error) => {
      showAlert({ type: 'error', label: 'Could not get operator' });
      return null;
    }),
});
