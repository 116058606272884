import { apiFetch } from 'utils/http';

import type { SiteEquipment } from 'types/api';

export const LocationGroupService = {
  /**
   * Fetch location group list
   */
  getLocationGroups: () => apiFetch('/admin/site-group/list?pageSize=5000'),

  getLocationGroup: (locationGroupId: number) => apiFetch(`/admin/site-group/${locationGroupId}`),
  /**
   * Fetch sites in location group
   */
  getSitesInLocationGroup: ({ locationGroupId }: { locationGroupId: number }) =>
    apiFetch(`/admin/site-group/${locationGroupId}/list`),

  /**
   * Fetch location group for site
   */
  getLocationGroupForSite: ({ siteId }: { siteId: number }) =>
    apiFetch(`/admin/site/${siteId}/site-group`),

  /**
   * Fetch access groups for location group
   */
  getAccessGroupsInLocationGroup: ({ locationGroupId }: { locationGroupId: number }) =>
    apiFetch(`/admin/site-group/${locationGroupId}/access-group/list`),

  /**
   * Create location group
   */
  createLocationGroup: ({ name, siteIds }: { name: string; siteIds: number[] }) =>
    apiFetch('/admin/site-group/create', {
      method: 'POST',
      body: {
        name,
        siteIds,
      },
    }),

  /**
   * Create access group
   */
  createAccessGroup: (body: {
    name: string;
    siteIds: number[];
    siteGroupId: number;
    enterpriseId?: number;
    subscriptionId?: number;
  }) =>
    apiFetch('/admin/access-group/create', {
      method: 'POST',
      body,
    }),

  /**
   * Update access group
   */
  updateAccessGroup: (body: {
    accessGroupId: number;
    name: string;
    transientChargeEnabled: boolean;
  }) =>
    apiFetch('/admin/access-group/settings', {
      method: 'PUT',
      body,
    }),

  /**
   * Link Location to Camera Equipment
   */
  linkLocationToCameraEquipment: (body: SiteEquipment) =>
    apiFetch(`/admin/location/${body.siteId}/equipment/${body.id}`, {
      method: 'PUT',
      body,
    }),
};
