import { createEffect } from 'effector';

import { apiFetch } from 'utils/http';

import { CreateEntLocationPayload } from 'types/api';

export const getEntLocationCapacityList = createEffect({
  handler: () => apiFetch('/admin/enterprise/location-capacity-list'),
});

export const getEntLocationTypeList = createEffect({
  handler: () => apiFetch('/admin/enterprise/location-type-list'),
});

export const getEnterpriseLocations = createEffect({
  handler: (enterpriseId: string | number) =>
    apiFetch(`/admin/enterprise/${enterpriseId}/location-list`),
});

export const createEntLocation = createEffect({
  handler: async ({ entId, body }: { entId: number | string; body: CreateEntLocationPayload }) => {
    const result = await apiFetch(`/admin/enterprise/${entId}/create-location`, {
      method: 'POST',
      body,
    });

    return result;
  },
});

export const getEntLocationById = createEffect({
  handler: ({ entId, locId }: { entId: string | number; locId: string | number }) =>
    apiFetch(`/admin/enterprise/${entId}/location/${locId}`),
});

export const updateEntLocation = createEffect({
  handler: ({
    entId,
    locId,
    body,
  }: {
    entId: number;
    locId: number;
    body: CreateEntLocationPayload;
  }) =>
    apiFetch(`/admin/enterprise/${entId}/location/${locId}`, {
      method: 'PUT',
      body,
    }),
});
