import { MsalProvider } from '@azure/msal-react';

import Providers from 'providers';
import { setDefaultAuthMethod } from 'utils/http';
import { msalInstance } from 'utils/msalConfig';

import Admin from './Admin';
import { AdminAppBox } from './AdminApp.styled';

import { CONNECT_TO_EXTERNAL_API } from 'constants/FeatureFlags';

if (CONNECT_TO_EXTERNAL_API) setDefaultAuthMethod('token');

export default function App() {
  return (
    <Providers>
      <MsalProvider instance={msalInstance}>
        <AdminAppBox>
          <Admin />
        </AdminAppBox>
      </MsalProvider>
    </Providers>
  );
}
