import { keyframes } from 'styled-components';

import theme from 'theme';

export const brandColorCycle = keyframes`
  0% {
    color: #5F59FF;
  }
  20% {
    color: #0EAA80;
  }
  40% {
    color: #FAD148;
  }
  60% {
    color: #FF5D52;
  }
  80% {
    color: #FF9CE0;
  }
  100% {
    color: #5F59FF;
  }
`;

export const brandColorCycleSpin = keyframes`
  0% {
    color: #5F59FF;
    transform: rotate(0deg);
  }
  20% {
    color: #0EAA80;
  }
  40% {
    color: #FAD148;
  }
  60% {
    color: #FF5D52;
  }
  80% {
    color: #FF9CE0;
  }
  100% {
    color: #5F59FF;
    transform: rotate(1440deg);
  }
`;

export const specialistInputFlash = keyframes`
  0% {
    background-color: ${theme.colors.grey7};
  }
  29.9% {
    background-color: ${theme.colors.grey7};
  }
  30% {
    background-color: ${theme.colors.grey5};
  }
  49.9% {
    background-color: ${theme.colors.grey7};
  }
  50% {
    background-color: ${theme.colors.grey7};
  }
  54.9% {
    background-color: ${theme.colors.grey7};
  }
  55% {
    background-color: ${theme.colors.grey5};
  }
  75% {
    background-color: ${theme.colors.grey7};
  }
  79.9% {
    background-color: ${theme.colors.grey7};
  }
  80% {
    background-color: ${theme.colors.grey5};
  }
  100% {
    background-color: ${theme.colors.grey7};
  }
`;

export const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

export const fadeInPop = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0.55,0.5,1);
  }
  55% {
    transform: scale3d(1,1,1);
  }
  100% {
    opacity: 1;
    transform: scale3d(1,1,1);
  }
`;

export const fadeInBounce = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 40%, 0) scale3d(0.25, 0.25, 1);
  }
  70% {
    opacity: 1;
    transform: translate3d(0,-19%,0) scale3d(1.1,1.13,1);
  }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0) scale3d(1,1,1);
  }
`;

export const slideUpFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }
  73% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const slideUpFadeInReverse = keyframes`
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  27% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }
`;

export const slideDownFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const pulse = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const slideUp = keyframes`
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;

export const expand = keyframes`
  0% {
    transform: scale3d(0, 0, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
`;

export const expandFadeIn = keyframes`
  0% {
    transform: scale3d(0.5, 0.5, 1);
    opacity: 0;
  }
  100% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
`;
