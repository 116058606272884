export const APPROACH_SETTING_IGNORE_ID = 0;
export const APPROACH_SETTING_IGNORE_NAME = 'Ignore';

export const APPROACH_SETTING_IGNORE = {
  id: APPROACH_SETTING_IGNORE_ID,
  name: APPROACH_SETTING_IGNORE_NAME,
};

export const APPROACH_SETTING_DISCARD_ID = 1;
export const APPROACH_SETTING_DISCARD_NAME = 'Discard';
export const APPROACH_SETTING_DISCARD = {
  id: APPROACH_SETTING_DISCARD_ID,
  name: APPROACH_SETTING_DISCARD_NAME,
};

export const APPROACH_SETTING_FLIP_ID = 2;
export const APPROACH_SETTING_FLIP_NAME = 'Flip';
export const APPROACH_SETTING_FLIP = {
  id: APPROACH_SETTING_FLIP_ID,
  name: APPROACH_SETTING_FLIP_NAME,
};

export const APPROACH_SETTING_MAP = {
  [APPROACH_SETTING_IGNORE_ID]: APPROACH_SETTING_IGNORE_NAME,
  [APPROACH_SETTING_DISCARD_ID]: APPROACH_SETTING_DISCARD_NAME,
  [APPROACH_SETTING_FLIP_ID]: APPROACH_SETTING_FLIP_NAME,
};

export const APPROACH_SETTING_LIST = [
  { [APPROACH_SETTING_IGNORE_ID]: APPROACH_SETTING_IGNORE_NAME },
  { [APPROACH_SETTING_DISCARD_ID]: APPROACH_SETTING_DISCARD_NAME },
  { [APPROACH_SETTING_FLIP_ID]: APPROACH_SETTING_FLIP_NAME },
];
