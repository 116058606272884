import { createEffect } from 'effector';

import { apiFetch } from 'utils/http';

import type { SiteEquipment } from 'types/api';

export const getLocationEquipmentList = createEffect({
  handler: ({ id }: { id: number }) => apiFetch(`/admin/location/${id}/equipment`),
});

export const createLocationEquipment = createEffect({
  handler: ({ locationId, body }: { locationId: number; body: SiteEquipment }) =>
    apiFetch(`/admin/location/${locationId}/equipment`, {
      method: 'POST',
      body,
    }),
});

export const updateLocationEquipment = createEffect({
  handler: ({
    locationId,
    equipmentId,
    body,
  }: {
    locationId: number;
    equipmentId: number;
    body: SiteEquipment;
  }) =>
    apiFetch(`/admin/location/${locationId}/equipment/${equipmentId}`, {
      method: 'PUT',
      body,
    }),
});
