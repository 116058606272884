import { createEffect } from 'effector';

import { apiFetch } from 'utils/http';

import type { WhitelistedEmailForm } from 'types/api';

export const getWhitelistedEmails = createEffect({
  handler: ({ subscriptionPlanId }: { subscriptionPlanId: number }) =>
    apiFetch(`/admin/subscription/plan/${subscriptionPlanId}/whitelisted-emails`),
});

export const addWhitelistedEmails = createEffect({
  handler: ({
    subscriptionPlanId,
    whitelistedEmailForm,
  }: {
    subscriptionPlanId: number;
    whitelistedEmailForm: WhitelistedEmailForm;
  }) =>
    apiFetch(`/admin/subscription/plan/${subscriptionPlanId}/whitelisted-emails`, {
      method: 'POST',
      body: whitelistedEmailForm,
    }),
});

export const deleteWhitelistedEmail = createEffect({
  handler: ({
    whitelistedEmailId,
    subscriptionPlanId,
  }: {
    whitelistedEmailId: number;
    subscriptionPlanId: number;
  }) =>
    apiFetch(
      `/admin/subscription/plan/${subscriptionPlanId}/whitelisted-email/${whitelistedEmailId}`,
      {
        method: 'DELETE',
      },
    ),
});
