import { createEffect } from 'effector';
import { stringify } from 'query-string';

import { apiFetch } from 'utils/http';

import type { SitePlatformSettings } from 'types/api';

export const updatePlatformSettings = createEffect({
  handler: ({ id, platformSettings }: { id: number; platformSettings: SitePlatformSettings }) =>
    apiFetch(`/admin/location/${id}/platform`, {
      method: 'PUT',
      body: platformSettings,
    }),
});

export const getUnattendedStatusFx = createEffect((params: { siteId: number }) =>
  apiFetch(`/admin/site/unattended-setting?${stringify(params)}`),
);

export const setUnattendedStatusFx = createEffect((params: { siteId: number; enabled: boolean }) =>
  apiFetch(`/admin/site/enable-unattended?${stringify(params)}`, { method: 'POST' }),
);

export const getEquipmentlessStatusFx = createEffect((params: { siteId: number }) =>
  apiFetch(`/admin/site/equipmentless-setting?${stringify(params)}`),
);

export const setEquipmentlessStatusFx = createEffect(
  (params: { siteId: number; enabled: boolean }) =>
    apiFetch(`/admin/site/enable-equipmentless?${stringify(params)}`, { method: 'POST' }),
);
