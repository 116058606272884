import { createEffect } from 'effector';

import { apiFetch } from 'utils/http';

export const getLocationValidations = createEffect(({ id }: { id: number }) =>
  apiFetch(`/admin/site/${id}/validations/v2`),
);

export const getValidationDetail = createEffect(({ validationId }: { validationId: number }) =>
  apiFetch(`/admin/validation/${validationId}`),
);
