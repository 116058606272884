import { lighten } from 'polished';
import styled from 'styled-components';

import { fadeIn } from 'styled/keyframes';
import { withStyledSystem, StyledSystemProps } from 'theme';

export const Wrap = styled.div<StyledSystemProps>`
  width: 100%;
  padding: 22px 0 21px 0;
  margin-left: -8px;
  margin-top: -1px;
  margin-bottom: 6px;
  max-width: 1080px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  font-weight: 700;
  line-height: auto;
  letter-spacing: 0.015em;
  font-family:
    'Neue Montreal',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  animation: ${fadeIn} 350ms linear 100ms 1 backwards;
  user-select: none;
  ${withStyledSystem}
`;

export const BreadCrumb = styled.div<StyledSystemProps & { index: number; count: number }>`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  font-size: 18px;
  user-select: none;

  &::before {
    content: '›';
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 32px;
    margin-top: -3px;
    font-size: 23px;
    color: ${({ theme }) => theme.colors.grey5};
    font-family: --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
      sans-serif;
    margin-right: 6px;
  }
  & + & {
    margin-left: 6px;
  }

  &:empty {
    display: none;
  }

  &:first-child::before {
    display: none;
  }

  & a {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    height: 27px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.0225em;
    padding: 0 6px;
    border-radius: 3px;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.brand};
    transition:
      background-color 150ms linear,
      box-shadow 150ms linear;

    &:hover {
      transition:
        background-color 100ms linear,
        box-shadow 100ms linear;
      background-color: ${({ theme }) => theme.colors.blue1};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.blue3};
    }
    &:active {
      transition: none;
      background-image: linear-gradient(rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0.075));
      color: ${({ theme }) => lighten(0.025, theme.colors.brand)};
    }
  }

  ${withStyledSystem}
`;
