import { createStore } from 'effector';

import { TaxExemptionApiData } from 'apps/admin/containers/LocationGroup/TaxContainer/types';
import { applyReducers } from 'effector-utils';

import {
  getLocationGroups,
  getSitesInLocationGroup,
  getAccessGroupsInLocationGroup,
  getLocationtaxExemptions,
} from './actions';

import type { Effect } from 'effector';
import type { DoneHandler } from 'effector-utils';
import type { IdNameObject } from 'types';
import type { APIResponse } from 'utils/http';

import type { SiteGroupSiteDetail, SiteGroupAccessGroupDetail } from 'types/api';

type LocationGroupState = {
  locationGroupList: IdNameObject[];
  locationGroupMap: { [key: number]: IdNameObject };
  locationGroupSiteListMap: {
    [key: number]: SiteGroupSiteDetail[];
  };
  locationGroupAccessGroupMap: {
    [key: number]: SiteGroupAccessGroupDetail[];
  };
  taxExemptionMap: TaxExemptionApiData[];
};

export const initialState: LocationGroupState = {
  locationGroupList: [],
  locationGroupMap: {},
  locationGroupSiteListMap: {},
  locationGroupAccessGroupMap: {},
  taxExemptionMap: [],
};

const store = createStore(initialState);

type Reducers = {
  getLocationGroups: {
    action: Effect<Parameters<typeof getLocationGroups>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getLocationGroups>[0], LocationGroupState>;
  };
  getSitesInLocationGroup: {
    action: Effect<Parameters<typeof getSitesInLocationGroup>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getSitesInLocationGroup>[0], LocationGroupState>;
  };
  getAccessGroupsInLocationGroup: {
    action: Effect<Parameters<typeof getAccessGroupsInLocationGroup>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getAccessGroupsInLocationGroup>[0], LocationGroupState>;
  };
  getLocationtaxExemptions: {
    action: Effect<Parameters<typeof getLocationtaxExemptions>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getLocationtaxExemptions>[0], LocationGroupState>;
  };
};

export const reducers: Reducers = {
  getLocationGroups: {
    action: getLocationGroups,
    done: (state, { result: { success, data } }) =>
      success
        ? {
            ...state,
            locationGroupList: data,
            locationGroupMap: data.reduce(
              (acc: { [key: number]: IdNameObject }, item: IdNameObject) => ({
                ...acc,
                [item.id]: item,
              }),
              {},
            ),
          }
        : state,
  },

  getSitesInLocationGroup: {
    action: getSitesInLocationGroup,
    done: (state, { params: { locationGroupId }, result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            locationGroupSiteListMap: {
              ...state.locationGroupSiteListMap,
              [locationGroupId]: data,
            },
          }
        : state,
  },

  getAccessGroupsInLocationGroup: {
    action: getAccessGroupsInLocationGroup,
    done: (state, { params: { locationGroupId }, result: { success, data } = {} }) =>
      success
        ? {
            ...state,
            locationGroupAccessGroupMap: {
              ...state.locationGroupAccessGroupMap,
              [locationGroupId]: data,
            },
          }
        : state,
  },
  getLocationtaxExemptions: {
    action: getLocationtaxExemptions,
    done: (state, { params: { locationId }, result: { success, data } }) =>
      success
        ? {
            ...state,
            taxExemptionMap: [
              ...state.taxExemptionMap,
              ...(data.taxExemptions as TaxExemptionApiData[]),
            ],
          }
        : state,
  },
};

export default applyReducers({ store, reducers });
