import { createEffect, createEvent } from 'effector';

import { apiFetch } from 'utils/http';

import { CreateEntLocSignPayload } from 'types/api';

export const getSignTypeList = createEffect({
  handler: () => apiFetch('/admin/enterprise/sign-type-list'),
});

export const getSignRemovalReasonList = createEffect({
  handler: () => apiFetch('/admin/enterprise/sign-removal-reason-list'),
});

export const getEntLocSigns = createEffect({
  handler: ({ entId, locId }: { entId: number; locId: number }) =>
    apiFetch(`/admin/enterprise/${entId}/location/${locId}/sign-list`),
});

export const createEntLocSign = createEffect({
  handler: async ({
    entId,
    locId,
    payload,
  }: {
    entId: number;
    locId: number;
    payload: CreateEntLocSignPayload;
  }) => {
    const result = await apiFetch(`/admin/enterprise/${entId}/location/${locId}/create-sign`, {
      method: 'POST',
      body: payload,
    });
    if (result && result.success) {
      return result;
    }
    throw new Error('Sign creation failed');
  },
});

export const getEntLocSignById = createEffect({
  handler: ({ entId, locId, signId }: { entId: number; locId: number; signId: number }) =>
    apiFetch(`/admin/enterprise/${entId}/location/${locId}/sign/${signId}`),
});

export const updateEntLocSign = createEffect({
  handler: ({
    entId,
    locId,
    signId,
    payload,
  }: {
    entId: number;
    locId: number;
    signId: number;
    payload: CreateEntLocSignPayload;
  }) =>
    apiFetch(`/admin/enterprise/${entId}/location/${locId}/sign/${signId}`, {
      method: 'PUT',
      body: payload,
    }),
});

export const resetSelectedEntLocSign = createEvent();

export const removeEntLocSign = createEffect({
  handler: ({
    entId,
    locId,
    signId,
    payload,
  }: {
    entId: number;
    locId: number;
    signId: number;
    payload: {
      signRemovalReasonId: number;
    };
  }) =>
    apiFetch(`/admin/enterprise/${entId}/location/${locId}/sign/${signId}`, {
      method: 'DELETE',
      body: payload,
    }),
});

export const getShortCodesBySignId = createEffect(({ signId }: { signId: string }) =>
  apiFetch(`/kiosk/${signId}/shortcodes`),
);

export const createShortCode = createEffect(
  ({ signCode, name, siteId }: { signCode: string; name: string; siteId: number }) =>
    apiFetch('/admin/sign/validation-short-codes', {
      method: 'POST',
      body: {
        signCode,
        name,
        siteId,
      },
    }),
);
