import { createEffect } from 'effector';

import { apiFetch } from 'utils/http';

import type { Location, UpdateHoursPayload } from 'types/api';

export const createLocation = createEffect({
  handler: ({ location }: { location: Location }) =>
    apiFetch('/admin/create-location', {
      method: 'POST',
      body: location,
    }),
});

export const deleteLocation = createEffect({
  handler: ({ id }: { id: number }) => apiFetch(`/admin/locations/${id}`, { method: 'DELETE' }),
});

export const getLocations = createEffect({
  handler: (searchQuery: string | null = null) =>
    apiFetch('/admin/location-list', {
      method: 'GET',
      params: {
        search: searchQuery,
        pageSize: 2000,
      },
    }),
});

export const getLocationDetail = createEffect({
  handler: ({ id }: { id: number }) => apiFetch(`/admin/location/${id}/detail`),
});

export const updateLocation = createEffect({
  handler: ({ id, location }: { id: number; location: Location }) =>
    apiFetch(`/admin/location/${id}/detail`, {
      method: 'PUT',
      body: location,
    }),
});

export const updateLocationHours = createEffect({
  handler: ({ id, payload }: { id: number; payload: UpdateHoursPayload }) =>
    apiFetch(`/admin/location/${id}/hours`, {
      method: 'POST',
      body: payload,
    }),
});

export const getLocationJoinQrCode = createEffect(
  ({ id, forceProd }: { id: number; forceProd?: boolean }) =>
    apiFetch(`/qr/site/${id}/get-or-create${forceProd ? '?forceProd=true' : ''}`, {
      method: 'POST',
    }),
);
