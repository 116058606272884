import { apiFetch } from 'utils/http';

import type { CreateUserV2Payload, SetUserPermissionsPayload } from 'types/api';

export const UserAdminService = {
  /**
   * Fetch internal user list
   * @param searchQuery Users search term
   */
  getUsers: (searchQuery: string | null = null) =>
    apiFetch('/users/internal', {
      params: {
        search: searchQuery,
        pageSize: 2000,
      },
    }),

  /**
   * Fetch user details
   * @param userId
   */
  getUserById: (userId: string | number) => apiFetch(`/user/${userId}`),

  /**
   * Create new User
   *
   * @param payload New user data
   * @see {@link CreateUserV2Payload}
   */
  createUser: (payload: CreateUserV2Payload) =>
    apiFetch('/admin/user', {
      method: 'POST',
      body: payload,
    }),

  updateUserInfo: (userId: number, payload: CreateUserV2Payload) =>
    apiFetch(`/admin/user/${userId}/info`, {
      method: 'PUT',
      body: payload,
    }),

  /**
   * Update user permissions
   *
   * @param requestData `{ userId: number | string, permissions: SetUserPermissionsPayload }`
   * @see {@link SetUserPermissionsPayload}
   */
  updatePermissions: ({
    userId,
    permissions,
  }: {
    userId: number | string;
    permissions: SetUserPermissionsPayload;
  }) =>
    apiFetch(`/user/${userId}/authorizations`, {
      method: 'POST',
      body: permissions,
    }),

  /**
   * Assigns user as an employee of an enterprise
   *
   * @param requestData `{ userId: number, employerId: number | null }`
   */
  setUserEmployer: ({ userId, employerId }: { userId: number; employerId: number | null }) =>
    apiFetch(`/admin/enterprise/${employerId}/assign-employee?userId=${userId}`, {
      method: 'POST',
    }),

  /**
   * Sets the `active` status of User
   *
   * @param requestData `{ userId: number, active: boolean }`
   */
  setUserActive: ({ userId, active }: { userId: number; active: boolean }) =>
    apiFetch(`/user/${userId}/set-active`, {
      method: 'POST',
      body: { active },
    }),
};
