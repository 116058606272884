import { createStore, Effect } from 'effector';

import { applyReducers } from 'effector-utils';

import { getSites } from './actions';

import type { DoneHandler } from 'effector-utils';
import type { APIResponse } from 'utils/http';

import { Site, SiteMap } from 'types/api';

export type State = {
  sites: Site[];
  siteMap: SiteMap;
};

export const initialState: State = {
  sites: [],
  siteMap: {},
};

const store = createStore(initialState);

type Reducers = {
  getSites: {
    action: Effect<Parameters<typeof getSites>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getSites>[0], State>;
  };
};

function siteMapReduce(map: SiteMap, site: Site): SiteMap {
  return {
    ...map,
    [site.id]: site,
  };
}

export const reducers: Reducers = {
  getSites: {
    action: getSites,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      sites: success ? data : state.sites,
      siteMap: success ? data.reduce(siteMapReduce, {}) : state.siteMap,
    }),
  },
};

export default applyReducers({ store, reducers });
