import { createEffect } from 'effector';

import { apiFetch } from 'utils/http';

import { Site } from 'types/api';

export const getSites = createEffect({
  handler: () =>
    apiFetch('/sites/search', {
      method: 'POST',
      body: {},
    }),
});

export const updateSite = createEffect({
  handler: ({ site }: { site: Site }) => apiFetch(''), // TODO
});
