import React, { lazy, Suspense } from 'react';

import { NavLink, Switch } from 'react-router-dom';

import { FormPageWrapper, DetailPageWrapper, ListPageWrapper } from 'apps/admin/AdminApp.styled';
import AuthenticatedRoute from 'apps/admin/components/AuthenticatedRoute';
import { navLinkStyle } from 'apps/admin/components/BreadCrumbs/active';
import { DynamicCrumb } from 'apps/admin/components/BreadCrumbs/DynamicCrumb';
import { AdminRouterParams } from 'types';

import { RoleName } from 'types/api/iam/Iam';

const OperatorsListPage = lazy(
  () => import('apps/admin/containers/resourceGroups/operators/OperatorsListPage'),
);
const CreateOperatorPage = lazy(
  () => import('apps/admin/containers/resourceGroups/operators/CreateOperatorPage'),
);
const OperatorDetailsPage = lazy(
  () => import('apps/admin/containers/resourceGroups/operators/OperatorDetailsPage'),
);

const OperatingGroupsFormPage = lazy(
  () => import('apps/admin/containers/resourceGroups/operatingGroups/CreateOperatingGroupPage'),
);
const OperatingGroupsDetailsPage = lazy(
  () => import('apps/admin/containers/resourceGroups/operatingGroups/OperatingGroupsDetailsPage'),
);

export const resourceGroupRoutesConfig = [
  // Operating Groups
  {
    exact: true,
    path: '/operators/:operatorId/operating-groups/create',
    breadcrumb: 'Create an Operating Group',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <FormPageWrapper maxWidth={800}>
          <OperatingGroupsFormPage />
        </FormPageWrapper>
      </Suspense>
    ),
  },
  {
    path: '/operators/:operatorId/operating-groups/:operatingGroupId',
    breadcrumb: ({ match: { params } }: { match: { params: AdminRouterParams } }) => (
      <NavLink
        to={`/operators/${params.operatorId}/operating-groups/${params.operatingGroupId}/details`}
        activeStyle={navLinkStyle}
      >
        <DynamicCrumb itemType="operating-groups" itemId={params.operatingGroupId} />
      </NavLink>
    ),
  },
  {
    path: '/operators/:operatorId/operating-groups/:operatingGroupId/details',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <DetailPageWrapper>
          <OperatingGroupsDetailsPage />
        </DetailPageWrapper>
      </Suspense>
    ),
  },
  {
    path: '/operators/:operatorId/operating-groups/:operatingGroupId/edit',
    breadcrumb: 'Edit',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <FormPageWrapper maxWidth={800}>
          <OperatingGroupsFormPage />
        </FormPageWrapper>
      </Suspense>
    ),
  },

  // Operators
  {
    exact: true,
    path: '/operators',
    breadcrumb: 'Operators',
    component: AuthenticatedRoute,
    roles: [RoleName.OPERATIONAL_ADMIN],
    children: (
      <Suspense fallback={null}>
        <ListPageWrapper>
          <OperatorsListPage />
        </ListPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/operating-groups',
    breadcrumb: 'Operating Groups',
    component: AuthenticatedRoute,
    roles: [RoleName.LEADER],
    children: (
      <Suspense fallback={null}>
        <ListPageWrapper>
          <OperatorsListPage />
        </ListPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/operators/create',
    breadcrumb: 'Create an Operator',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <FormPageWrapper maxWidth={800}>
          <CreateOperatorPage />
        </FormPageWrapper>
      </Suspense>
    ),
  },
  {
    path: '/operators/:operatorId',
    breadcrumb: ({ match: { params } }: { match: { params: AdminRouterParams } }) => (
      <NavLink to={`/operators/${params.operatorId}/details`} activeStyle={navLinkStyle}>
        <DynamicCrumb itemType="operator" itemId={params.operatorId} />
      </NavLink>
    ),
  },
  {
    path: '/operators/:operatorId/details',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <DetailPageWrapper>
          <OperatorDetailsPage />
        </DetailPageWrapper>
      </Suspense>
    ),
  },
];

export default function ResourceGroupRoutes() {
  return (
    <Switch>
      {resourceGroupRoutesConfig.map(
        ({ component: Component, breadcrumb, ...props }) =>
          Component && <Component key={props.path} {...props} />,
      )}
    </Switch>
  );
}
