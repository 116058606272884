import { createEffect } from 'effector';

import { LocationGroupService } from 'apps/admin/services';
import { TaxService } from 'apps/admin/services/TaxService';

export const getLocationGroups = createEffect(LocationGroupService.getLocationGroups);

export const getSitesInLocationGroup = createEffect(LocationGroupService.getSitesInLocationGroup);

export const getAccessGroupsInLocationGroup = createEffect(
  LocationGroupService.getAccessGroupsInLocationGroup,
);

export const getLocationtaxExemptions = createEffect(TaxService.getLocationTaxExemptions);
