import { createEffect, createEvent } from 'effector';

import { LaneService } from 'apps/admin/services';

export const getLocationLanes = createEffect(LaneService.getLanes);

export const getLocationDevices = createEffect(LaneService.getLaneDevices);

export const getLocationLaneDetail = createEffect(LaneService.getLaneDetail);

export const getLaneGateDetail = createEffect(LaneService.getGateDetail);

export const removeLaneGate = createEvent<{ laneId: number }>();
