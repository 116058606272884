import { useUnit } from 'effector-react';
import styled from 'styled-components';

import { TaxExemptionApiData } from 'apps/admin/containers/LocationGroup/TaxContainer/types';
import { enterpriseStore } from 'apps/admin/state/enterprise';
import { iamStore } from 'apps/admin/state/iam';
import { locationStore } from 'apps/admin/state/location';
import { locationGroupStore } from 'apps/admin/state/location-group';
import { siteStore } from 'apps/admin/state/site';
import { userStore } from 'apps/admin/state/user';
import { formatLaneName } from 'apps/admin/utils/formatLaneName';
import { fadeIn } from 'styled/keyframes';

type DynamicCrumbProps = {
  itemType:
    | 'sites'
    | 'zones'
    | 'locations'
    | 'location-lane'
    | 'enterprise'
    | 'enterprise-location'
    | 'validating-location'
    | 'users'
    | 'visits'
    | 'operating-groups'
    | 'operator'
    | 'tax'
    | 'pass-plan';
  itemId: string | number;
};

const Loading = styled.span`
  color: var(--colors-grey3);
  animation: ${fadeIn} 100ms linear 150ms 1 backwards;
`;

export function DynamicCrumb({ itemType, itemId }: DynamicCrumbProps) {
  const id = Number(itemId);
  const { selectedEnterprise, selectedEntLocation } = useUnit(enterpriseStore);
  const { sites } = useUnit(siteStore);
  const { userMap, iamUserMap } = useUnit(userStore);

  const { iamMapping } = useUnit(iamStore);
  const { locationDetails, laneDetailMap } = useUnit(locationStore);
  const { locationGroupMap } = useUnit(locationGroupStore);
  const { taxExemptionMap } = useUnit(locationGroupStore);

  const selectedTaxExemption = taxExemptionMap?.find(
    (tax: TaxExemptionApiData) => tax.taxExemptionId === itemId,
  );

  const selectedUser = userMap[id];
  const selectedIamUser = iamUserMap[itemId];
  const selectedOperatingGroup = iamMapping[itemId];
  const operatorName = selectedOperatingGroup?.name;
  if (itemType === 'sites') {
    const site = sites.find((s) => s.id === id);
    return <span>{site ? site.name : id}</span>;
  }

  if (itemType === 'zones') {
    const location = locationDetails[id];
    return <span>{location ? location.name : id}</span>;
  }

  if (itemType === 'location-lane') {
    const { laneDetails: lane } = laneDetailMap[id] || {};
    return <span>{lane ? formatLaneName(lane) : `Lane #${id}`}</span>;
  }

  if (itemType === 'locations') {
    return <span>{locationGroupMap[id]?.name ?? id}</span>;
  }

  if (itemType === 'enterprise') {
    if (!selectedEnterprise || selectedEnterprise.id !== id) {
      return (
        <span>
          <Loading>Loading&hellip;&nbsp;</Loading>
        </span>
      );
    }
    if (selectedEnterprise) {
      return <span>{selectedEnterprise.name}</span>;
    }
    return <span>{id}</span>;
  }

  if (itemType === 'enterprise-location') {
    if (!selectedEntLocation || selectedEntLocation.id !== id) {
      return (
        <span>
          <Loading>Loading&hellip;&nbsp;</Loading>
        </span>
      );
    }
    if (selectedEntLocation) {
      return <span>{selectedEntLocation.name}</span>;
    }
    return <span>{id}</span>;
  }

  if (itemType === 'validating-location') {
    if (!selectedEntLocation || selectedEntLocation.id !== id) {
      return (
        <span>
          <Loading>Loading&hellip;&nbsp;</Loading>
        </span>
      );
    }
    if (selectedEntLocation) {
      return <span>{selectedEntLocation.name}</span>;
    }
    return <span>{id}</span>;
  }

  if (itemType === 'users' && selectedUser) {
    return <span>{`${selectedUser.firstName} ${selectedUser.lastName}`}</span>;
  }
  if (itemType === 'users') {
    if (selectedIamUser) {
      return <span>{`${selectedIamUser.firstname} ${selectedIamUser.lastname}`}</span>;
    }
    return <span>{itemId}</span>;
  }

  if (itemType === 'operator') {
    return <span>{operatorName}</span>;
  }

  if (itemType === 'operating-groups') {
    return <span>{operatorName}</span>;
  }

  if (itemType === 'tax') {
    return <span>{selectedTaxExemption?.displayName ?? id}</span>;
  }
  if (itemType === 'pass-plan') {
    return <span>Pass Plan Details</span>;
  }

  return <span>{id}</span>;
}
