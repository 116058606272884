import { createEffect, createEvent } from 'effector';
import { stringify } from 'query-string';

import { apiFetch } from 'utils/http';

import { Enterprise } from 'types/api';

export const getEnterpriseList = createEffect({
  handler: ({ search }: { search?: string } = {}) =>
    apiFetch(`/admin/enterprise-list?${stringify({ search })}`),
});

export const createEnterprise = createEffect({
  handler: async (body: Enterprise) => {
    const result = await apiFetch('/admin/create-enterprise', {
      method: 'POST',
      body: removeEmptyContactDetails(body),
    });
    if (result && result.success) {
      return result;
    }
    throw new Error('Enterprise creation failed');
  },
});

export const clearSelectedEnterprise = createEvent();

export const getEnterpriseById = createEffect({
  handler: (enterpriseId: string | number) => apiFetch(`/admin/enterprise/${enterpriseId}/detail`),
});

export const updateEnterprise = createEffect({
  handler: (body: Enterprise) =>
    apiFetch(`/admin/enterprise/${body.id}/detail`, {
      method: 'PUT',
      body: removeEmptyContactDetails(body),
    }),
});

export const deleteEnterprise = createEffect({
  handler: ({ entId }: { entId: number }) =>
    apiFetch(`/admin/enterprise/${entId}`, {
      method: 'DELETE',
    }),
});

export const sendAdminInvitation = createEffect(
  ({ enterpriseId }: { enterpriseId: number | string }) =>
    apiFetch(`/admin/enterprise/${enterpriseId}/admin-invitation`, { method: 'POST' }),
);

export const sendBillingInvitation = createEffect(
  ({ enterpriseId }: { enterpriseId: number | string }) =>
    apiFetch(`/admin/enterprise/${enterpriseId}/billing-invitation`, { method: 'POST' }),
);

export const assignOperatorToEnterprise = createEffect(
  ({ enterpriseId, operatorId }: { enterpriseId: number; operatorId: number }) =>
    apiFetch(`/admin/enterprise/${enterpriseId}/assign-operator?operatorId=${operatorId}`, {
      method: 'POST',
    }),
);

function removeEmptyContactDetails(body: Enterprise) {
  return {
    ...body,
    admin: {
      email: body?.admin?.email || undefined,
      firstName: body?.admin?.firstName || undefined,
      lastName: body?.admin?.lastName || undefined,
      phone1: body?.admin?.phone1 || undefined,
      phone2: body?.admin?.phone2 || undefined,
    },
    billing: {
      ...(body.billing || {}),
      email: body?.billing?.email || undefined,
      firstName: body?.billing?.firstName || undefined,
      lastName: body?.billing?.lastName || undefined,
      phone1: body?.billing?.phone1 || undefined,
      phone2: body?.billing?.phone2 || undefined,
    },
  };
}
