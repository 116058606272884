import { createEffect } from 'effector';

import HospitalityService from 'apps/admin/services/HospitalityService';

export const getEnterpriseIntakePartners = createEffect(
  HospitalityService.getEnterpriseIntakePartner,
);

export const createEnterpriseIntakePartner = createEffect(
  HospitalityService.createEnterpriseIntakePartner,
);

export const updateEnterpriseIntakePartner = createEffect(
  HospitalityService.updateEnterpriseIntakePartner,
);
