import { apiFetch } from 'utils/http';

export const FeeService = {
  getTransactionFeePlans: () => apiFetch('/admin/transaction-fees'),

  getTransactionFeesForSite: ({ siteId }: { siteId: number }) =>
    apiFetch(`/admin/transaction-fees/fees-for-site?siteId=${siteId}`),

  enableTransactionFeeForSite: ({ siteId, feeId }: { siteId: number; feeId: number }) =>
    apiFetch(`/admin/transaction-fees/${feeId}?siteId=${siteId}`, { method: 'POST' }),

  disableTransactionFeeForSite: ({ siteFeeId }: { siteFeeId: number }) =>
    apiFetch(`/admin/transaction-fees/remove-fee-from-site?siteFeeId=${siteFeeId}`, {
      method: 'DELETE',
    }),

  getSubscriptionFeePlans: () => apiFetch('/admin/subscription-fees'),

  getSubscriptionFeesForSite: ({ siteId }: { siteId: number }) =>
    apiFetch(`/admin/subscription-fees/fees-for-site?siteId=${siteId}`),

  enableSubscriptionFeeForSite: ({ siteId, feeId }: { siteId: number; feeId: number }) =>
    apiFetch(`/admin/subscription-fees/${feeId}?siteId=${siteId}`, { method: 'POST' }),

  disableSubscriptionFeeForSite: ({ siteFeeId }: { siteFeeId: number }) =>
    apiFetch(`/admin/subscription-fees/remove-fee-from-site?siteFeeId=${siteFeeId}`, {
      method: 'DELETE',
    }),

  createSubscriptionFeePlan: (body: {
    minimum: number;
    maximum: number;
    percent?: number;
    flatAmount?: number;
  }) =>
    apiFetch('/admin/subscription-fees', {
      method: 'POST',
      body,
    }),

  getSubscriptionFeeOverrideConfig: ({ subscriptionId }: { subscriptionId: number }) =>
    apiFetch(`/v2/subscriptions/${subscriptionId}/fees/override`, {
      method: 'GET',
    }),

  updateSubscriptionFeeOverrideConfig: ({
    subscriptionId,
    enabled,
  }: {
    subscriptionId: number;
    enabled: boolean;
  }) =>
    apiFetch(`/v2/subscriptions/${subscriptionId}/fees/override`, {
      method: 'POST',
      body: { enabled },
    }),

  createTransactionFeePlan: (body: { minimum: number; maximum: number; percent?: number }) =>
    apiFetch('/admin/transaction-fees', {
      method: 'POST',
      body,
    }),
};
