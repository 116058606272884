import { apiFetch } from 'utils/http';

export const SearchService = {
  /**
   * Fetch sites
   * @param searchQuery search term
   */
  getLocations: (searchQuery: string) =>
    apiFetch('/admin/location-list', {
      params: {
        search: searchQuery,
        pageSize: 2000,
      },
    }),

  /**
   * Fetch sites
   * @param searchQuery search term
   */
  getEnterprise: (searchQuery: string) =>
    apiFetch('/admin/enterprise-list', {
      params: {
        search: searchQuery,
      },
    }),

  /**
   * Fetch devices
   * @param searchQuery search term
   */
  searchDevices: (searchQuery: string) =>
    apiFetch('/ehcs/devices/search', {
      params: {
        search: searchQuery,
        pageSize: 100,
      },
    }),

  /**
   * Fetch site details
   * @param siteId location siteId
   */
  getSiteDetails: (siteId: number) => apiFetch(`/admin/location/${siteId}/detail`),
};
