import React, { lazy, Suspense } from 'react';

import { NavLink, Switch } from 'react-router-dom';

import { FormPageWrapper, DetailPageWrapper, ListPageWrapper } from 'apps/admin/AdminApp.styled';
import AuthenticatedRoute from 'apps/admin/components/AuthenticatedRoute';
import { navLinkStyle } from 'apps/admin/components/BreadCrumbs/active';
import { DynamicCrumb } from 'apps/admin/components/BreadCrumbs/DynamicCrumb';
import { KEYCLOAK_ENABLED } from 'config/env';
import { AdminRouterParams } from 'types';

const CreateUserContainer = lazy(() => import('apps/admin/containers/user/CreateUserContainer'));
const UserDetailContainer = lazy(() => import('apps/admin/containers/user/UserDetailContainer'));
const UserEditContainer = lazy(() => import('apps/admin/containers/user/EditUserContainer'));
const UserListContainer = lazy(() => import('apps/admin/containers/user/UserListContainer'));

// For manager app
const UserIamPage = lazy(() => import('apps/admin/containers/user/iam/UserIamPage'));
const UserIamListPage = lazy(() => import('apps/admin/containers/user/iam/UserIamListPage'));
const IamUserFormPage = lazy(
  () => import('apps/admin/containers/user/iam/IamUserFormPage/IamUserFormPage'),
);

export const userRoutesConfig = [
  {
    exact: true,
    path: '/users',
    breadcrumb: 'Users',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <ListPageWrapper>
          {KEYCLOAK_ENABLED ? <UserIamListPage /> : <UserListContainer />}
        </ListPageWrapper>
      </Suspense>
    ),
  },
  {
    exact: true,
    path: '/users/create',
    breadcrumb: 'Create a new user',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <FormPageWrapper maxWidth={800}>
          {KEYCLOAK_ENABLED ? <IamUserFormPage /> : <CreateUserContainer />}
        </FormPageWrapper>
      </Suspense>
    ),
  },
  {
    path: '/users/:userId',
    breadcrumb: ({ match: { params } }: { match: { params: AdminRouterParams } }) => (
      <NavLink to={`/users/${params.userId}/details`} activeStyle={navLinkStyle}>
        <DynamicCrumb itemType="users" itemId={params.userId} />
      </NavLink>
    ),
  },
  {
    path: '/users/:userId/details',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <DetailPageWrapper>
          {KEYCLOAK_ENABLED ? <UserIamPage /> : <UserDetailContainer />}
        </DetailPageWrapper>
      </Suspense>
    ),
  },
  {
    path: '/users/:userId/edit',
    breadcrumb: 'Edit',
    component: AuthenticatedRoute,
    children: (
      <Suspense fallback={null}>
        <FormPageWrapper maxWidth={800}>
          {KEYCLOAK_ENABLED ? <IamUserFormPage editForm /> : <UserEditContainer />}
        </FormPageWrapper>
      </Suspense>
    ),
  },
];
export default function UserRoutes() {
  return (
    <Switch>
      {userRoutesConfig.map(
        ({ component: Component, breadcrumb, ...props }) =>
          Component && <Component key={props.path} {...props} />,
      )}
    </Switch>
  );
}
